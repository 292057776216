import { useCallback, useEffect } from "react"

export const useTGDsignature = ()=>{
    const signature = useCallback(()=> {
        console.log(`%c 
        ▒▒▄▀▀▀▀▀▄▒▒▒▒▒▄▄▄▄▄▄▄▒▒
        ▒▐░▄░░░▄░▌▒▒▄█▄DEV´S▄█▄▒
        ▒▐░▀▀░▀▀░▌▒▒▒▒░░░░░░░▒▒▒
        ▒▒▀▄░═░▄▀▒▒▒▒▒░░░░░░░▒▒▒
        ▒▒▐░▀▄▀░▌▒▒▒▒▒░░TGD░░▒▒▒     
        `, "font-family:monospace")
        
    },[])

    useEffect(()=>{
        signature()
    },[signature])
}


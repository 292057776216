import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useUpdateStock } from "../services/service.stock";
import { StockInterface } from "../models/StockInterface";
import ComponentMode from "../../../components/componentMode/ComponentMode";
import StatusAndMessage from "../../../shared/queries/errors/components/StatusAndMessage";
import FlexContainer from "../../../components/containers/FlexContainer";
import { TextField } from "@material-ui/core";
import { TypeFormRegex } from "../../../shared/regExp/regExp";
import CustomButton from "../../../components/buttons/CustomButton";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface Props {
  item: StockInterface;
}

const NewStockQuantityForm: React.FC<Props> = ({ item }) => {
  const { lang } = useLangLabels();
  const { register, errors, watch, handleSubmit } = useForm();
  const { updateStock, status, error, message } = useUpdateStock();

  const title = lang.lubricationSection.manageStock;

  useEffect(() => {
    if (item) {
      Object.entries(item).map(([key, value]: [string, any]) => {
        register(
          {
            name: key,
            value,
          },
          {
            required: {
              value: !!value,
              message: lang.formMessages.required,
            },
          }
        );
      });
    }
  }, [item, register]);

  const submit = (data: any) => {
    updateStock(data);
  };

  return (
    <>
      <ComponentMode key={item.id} variant="iconButton" mode="popover" title={title} icon="stock">
        <StatusAndMessage status={status} message={message} error={error} />
        <FlexContainer flexDirection={"column"}>
          <TextField
            inputRef={register({
              pattern: {
                value: TypeFormRegex.integer,
                message: "Solo numeros",
              },
            })}
            name="actualStock"
            size="small"
            variant="outlined"
            defaultValue={watch("actualStock")}
            label={errors?.actualStock?.message || lang.stock.actualStock}
            color={errors?.actualStock?.message ? "primary" : "secondary"}
          />

          <CustomButton status={status} action={handleSubmit(submit)}>
            {lang.crudActions.save}
          </CustomButton>
        </FlexContainer>
      </ComponentMode>
    </>
  );
};

export default NewStockQuantityForm;
